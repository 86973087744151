import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div>
      <div className="mb-16 text-center">
      <StaticImage src="../images/logo-dld.png" placeholder="none" />
      </div>
      <h1 className="text-center">Dreams Line Design - Luxury Italian Furniture</h1>
      <p className="text-center">Dreams Line Design is an amazing Italian Luxury Furniture and Lighting store. Specializing in the manufacturing and distribution of premium quality, luxury furniture; serving homeowners, construction companies, interior design businesses and more.</p>

      <p className="text-center">By representing only the best of made in Italy, we can grant the quality of every single piece of furniture that you buy from us.</p>

      <p className="text-center">Dreams Line Design was born from the minds of the people behind Archiproject: A humble team of visionaries who’re passionate about turning their valued clients dreams into reality. So, in case you need, we are able and honored to plan your environment and suggest the best technical and aesthetic solution for you!</p>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-3 mt-12">
      <div className="text-center">
        <StaticImage src="../images/top.png" />
        <h3 className="font-light mb-0 mt-5">Italian Quality Forniture</h3>
        <p>100% made in Italy</p>
      </div>

      <div className="text-center">
        <StaticImage src="../images/world.png" />
        <h3 className="font-light mb-0 mt-5">Worldwide Shipping</h3>
        <p>We deliver anywhere in the world!</p>
      </div>

      <div className="text-center">
        <StaticImage src="../images/custom.png" />
        <h3 className="font-light mb-0 mt-5">Custom Made Furniture</h3>
        <p>We make custom! Contact us to find more</p>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-5 mt-12 gap-8">
      <div className="text-center">
        <StaticImage src="../images/living.png" aspectRatio={1} />
        <span>Living Room</span>
      </div>
      <div className="text-center">
        <StaticImage src="../images/bedroom.jpg" aspectRatio={1} />
        <span>Bedroom</span>
      </div>
      <div className="text-center">
        <StaticImage src="../images/bath.png" aspectRatio={1} />
        <span>Bathroom</span>
      </div>
      <div className="text-center">
        <StaticImage src="../images/accessories.png" aspectRatio={1} />
        <span>Accessories</span>
      </div>
      <div className="text-center">
        <StaticImage src="../images/outdor.png" aspectRatio={1} />
        <span>Outdoor</span>
      </div>
    </div>

    <div className="bg-white prose max-w-none p-8 mt-12 rounded-lg">
      <h4 className="text-center">Featured Brands</h4>
      <div className="flex">
        <StaticImage src="../images/desiree-logo.png" />
        <StaticImage src="../images/florence-logo.jpg" />
        <StaticImage src="../images/minotti-logo.png" />
        <StaticImage src="../images/turri-logo.png" />
        <StaticImage src="../images/ulivi-logo.png" />
        <StaticImage src="../images/zanaboni-logo-1.png" />
        <StaticImage src="../images/cortezari-logo.png" />
      </div>
    </div>

    <div className="mt-12 text-center border-t border-yellow-600">
      <h2>Get in touch</h2>
      <div>
        <h3>Archiproject LLC</h3>
        <p>407 Lincoln Road Suite 504 – FL 33139 Miami Beach FLORIDA<br></br>
        +39 0587 733914 // +39 0587 730603<br></br>
          info(at)dreamslinedesign.com</p>
          <p className="text-sm">Privacy Policy</p>
      </div>
    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Italian Luxury Furniture - 100% Made in Italy" />

export default IndexPage
